<template>
  <forex-calc class="ma-8"></forex-calc>
</template>

<script>
/* eslint-disable */
import ForexCalc from "@/components/forexcalc.vue";

export default {
  name: "Forex",
  components: {ForexCalc}
}
</script>

<style scoped>

</style>