<template>
  <crypto></crypto>
</template>
<script>


import Crypto from "@/components/Crypto.vue";

export default {
  name: "CryptoPage",
  components: {Crypto},
}
</script>
